<template>
  <div :class="$style.mainContainerWrapper">
    <div
      v-if="isMobileCarousel"
      :class="[$style.mainContainer, $style.mainContainerMobile]"
      :style="{'--main-container-size': content.components.length}"
    >
      <aw-cms-card
        v-for="(tile, i) in content.components"
        :key="tile.id"
        :content="{...tile, backgroundColor: {hex: $styleVariables[backgroundColor], alpha: 1 }}"
        :class="[$style[`tile-${i}`], $style.tile]"
        tile-type="verticalTile"
        :analytics-banner-interaction="analyticsBannerInteraction"
        :analytics-measurable="analyticsMeasurable"
      />
    </div>
    <div v-else :class="[$style.mainContainerWrapper, $style.mainContainerMobile]">
      <div
        v-for="(separatedContent, index) in separatedContents"
        :key="index"
        :style="{'--main-container-size': separatedContent.length}"
        :class="[$style.mainContainer, $style.mainContainerTilesMobile, $style[`size-${separatedContent.length}`]]"
      >
        <aw-cms-card
          v-for="(tile, tileIdx) in separatedContent"
          :key="tile.id"
          :content="{...tile, backgroundColor: {hex: $styleVariables[backgroundColor], alpha: 1 }}"
          :class="$style[`tile-${tileIdx}`]"
          :tile-type="tileSettings[separatedContent.length]?.[cmsDevice]?.[tileIdx] || 'full'"
          :has-min-width="false"
          :analytics-banner-interaction="analyticsBannerInteraction"
          :analytics-measurable="analyticsMeasurable"
        />
      </div>
    </div>
    <div :class="[$style.mainContainerWrapper, $style.mainContainerDesktop]">
      <div
        v-for="(separatedContent, index) in separatedContents"
        :key="index"
        :style="{'--main-container-size': separatedContent.length}"
        :class="[$style.mainContainer, $style[`size-${separatedContent.length}`]]"
      >
        <aw-cms-card
          v-for="(tile, tileIdx) in separatedContent"
          :key="tile.id"
          :content="{...tile, backgroundColor: {hex: $styleVariables[backgroundColor], alpha: 1 }}"
          :class="$style[`tile-${tileIdx}`]"
          :tile-type="tileSettings[separatedContent.length]?.[cmsDevice]?.[tileIdx] || 'full'"
          :analytics-banner-interaction="analyticsBannerInteraction"
          :analytics-measurable="analyticsMeasurable"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwFullTiles',
    components: {
      AwCmsCard: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCmsCard')),
    },
    mixins: [analyticsBannerInteractionMixin],
    props: {
      content: {
        type: Object,
        required: true,
      },
      backgroundColor: {
        type: String,
        default: 'colorBackground3',
      },
      isMobileCarousel: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        isMounted: false,
        tileSettings: {
          1: {
            mobile: ['verticalTile'],
            tablet: ['full'],
            desktop: ['full'],
          },
          2: {
            mobile: ['verticalTile', 'verticalTile'],
            tablet: ['verticalTile', 'verticalTile'],
            desktop: ['half', 'half'],
          },
          3: {
            mobile: ['verticalTile', 'verticalTile', 'verticalTile'],
            tablet: ['full', 'verticalTile', 'verticalTile'],
            desktop: ['full', 'half', 'half'],
          },
          4: {
            mobile: ['verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
            tablet: ['verticalTile', 'verticalTile', 'verticalTile', 'full'],
            desktop: ['verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
          },
          5: {
            mobile: ['verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
            tablet: ['full', 'verticalTile', 'verticalTile', 'verticalTile', 'full'],
            desktop: ['full', 'half', 'half', 'half', 'half'],
          },
          6: {
            mobile: ['verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
            tablet: ['full', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'full'],
            desktop: ['half', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'half'],
          },
          7: {
            mobile: ['verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
            tablet: ['full', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'full', 'full'],
            desktop: ['half', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'half', 'full'],
          },
          8: {
            mobile: ['verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
            tablet: ['full', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'full'],
            desktop: ['half', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'half', 'half', 'half'],
          },
          9: {
            mobile: ['verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
            tablet: ['full', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
            desktop: ['half', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'half', 'verticalTile', 'verticalTile', 'verticalTile'],
          },
          10: {
            mobile: ['verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
            tablet: ['full', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'full', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
            desktop: ['half', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile', 'half', 'verticalTile', 'verticalTile', 'verticalTile', 'verticalTile'],
          },
        },
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      separatedContents () {
        const results = [];
        for (let i = 0; i < this.content.components.length; i += 10) {
          results.push(this.content.components.slice(i, i + 10));
        }
        return results;
      },
      cmsDevice () {
        if (this.isMounted) {
          return this.screenRange['desktop-small-min'] ? 'desktop' : (this.screenRange['tablet-min'] ? 'tablet' : 'mobile');
        }
        return 'desktop';
      },
    },
    mounted () {
      this.isMounted = true;
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.mainContainerMobile {
  @include tablet(min) {
    display: none !important;
  }

  @include mobile(max) {
    .tile:last-of-type {
      width: 216px;

      [data-cms-card-content] {
        margin-right: 16px;
      }
    }
  }
}

.mainContainerDesktop {
  @include mobile(max) {
    display: none !important;
  }
}

.mainContainer {
  display: grid;

  overflow-x: auto;
  overflow-y: hidden;

  width: 100%;
  height: 100%;
  gap: 16px;

  grid-template-columns: repeat(var(--main-container-size, 10), 200px);
  scrollbar-width: none;

  @include tablet(min) {
    gap: 24px;
  }

  @include desktop-small(min) {
    gap: 32px;
  }

  @include desktop-medium(min) {
    gap: 40px;
  }

  &TilesMobile {
    &.size-1 {
      grid-template-columns: 1fr;
      grid-template-rows: minmax(180px, max-content);
    }

    &.size-2 {
      grid-template-columns: 1fr 1fr;
    }

    &.size-3 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: minmax(180px, max-content) minmax(180px, max-content);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-1 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .tile-2 {
        grid-area: 2 / 2 / 3 / 3;
      }
    }

    &.size-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto auto auto;

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-1 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .tile-2 {
        grid-area: 2 / 2 / 3 / 3;
      }

      .tile-3 {
        grid-area: 3 / 1 / 4 / 3;
      }
    }

    &.size-5 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto auto auto;

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-1 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .tile-2 {
        grid-area: 2 / 2 / 3 / 3;
      }

      .tile-3 {
        grid-area: 3 / 1 / 4 / 2;
      }

      .tile-4 {
        grid-area: 3 / 2 / 4 / 3;
      }
    }

    &.size-6, &.size-7, &.size-8, &.size-9, &.size-10 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: minmax(180px, max-content) minmax(364px, max-content) minmax(364px, max-content) minmax(180px, max-content);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-1 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .tile-2 {
        grid-area: 2 / 2 / 3 / 3;
      }

      .tile-3 {
        grid-area: 3 / 1 / 4 / 3;
      }

      .tile-4 {
        grid-area: 4 / 1 / 5 / 2;
      }

      .tile-5 {
        grid-area: 4 / 2 / 5 / 3;
      }
    }

    &.size-7 {
      grid-template-rows: minmax(180px, max-content) minmax(364px, max-content) minmax(364px, max-content) minmax(180px, max-content) minmax(180px, max-content);

      .tile-6 {
        grid-area: 5 / 1 / 6 / 3;
      }
    }

    &.size-8 {
      grid-template-columns: repeat(2, 1fr);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-1 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .tile-2 {
        grid-area: 2 / 2 / 3 / 3;
      }

      .tile-3 {
        grid-area: 3 / 1 / 4 / 3;
      }

      .tile-4 {
        grid-area: 4 / 1 / 5 / 2;
      }

      .tile-5 {
        grid-area: 4 / 2 / 5 / 3;
      }

      .tile-6 {
        grid-area: 5 / 1 / 6 / 2;
      }

      .tile-7 {
        grid-area: 5 / 2 / 6 / 3;
      }
    }

    &.size-9 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: minmax(180px, max-content) repeat(3, 364px);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-1 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .tile-2 {
        grid-area: 2 / 2 / 3 / 3;
      }

      .tile-3 {
        grid-area: 3 / 1 / 4 / 2;
      }

      .tile-4 {
        grid-area: 3 / 2 / 4 / 3;
      }

      .tile-5 {
        grid-area: 4 / 1 / 5 / 3;
      }

      .tile-6 {
        grid-area: 5 / 1 / 6 / 2;
      }

      .tile-7 {
        grid-area: 5 / 2 / 6 / 3;
      }

      .tile-8 {
        grid-area: 6 / 1 / 7 / 3;
      }
    }

    .size-10 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: minmax(180px, max-content) repeat(4, 364px);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-1 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .tile-2 {
        grid-area: 2 / 2 / 3 / 3;
      }

      .tile-3 {
        grid-area: 3 / 1 / 4 / 3;
      }

      .tile-4 {
        grid-area: 4 / 1 / 5 / 2;
      }

      .tile-5 {
        grid-area: 4 / 2 / 5 / 3;
      }

      .tile-6 {
        grid-area: 5 / 1 / 6 / 2;
      }

      .tile-7 {
        grid-area: 5 / 2 / 6 / 3;
      }

      .tile-8 {
        grid-area: 6 / 1 / 7 / 3;
      }

      .tile-9 {
        grid-area: 7 / 1 / 8 / 3;
      }
    }

  }

  &Wrapper {
    display: grid;
    @include tablet(min) {
      gap: 24px;
    }
    @include desktop-small(min) {
      gap: 32px;
    }
    @include desktop-medium(min) {
      gap: 40px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.size-1 {
    @include tablet(min) {
      grid-template-columns: 1fr;
      grid-template-rows: minmax(180px, max-content);
    }
  }

  &.size-2 {
    @include tablet(min) {
      grid-template-columns: 1fr 1fr;
    }

    @include desktop-small(min) {
      grid-template-rows: minmax(228px, max-content);
    }
  }

  &.size-3 {
    @include tablet(min) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: minmax(180px, max-content) minmax(180px, max-content);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-1 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .tile-2 {
        grid-area: 2 / 2 / 3 / 3;
      }
    }

    @include desktop-small(min) {
      grid-template-rows: minmax(180px, max-content) minmax(228px, max-content);
    }
  }

  &.size-4 {
    @include tablet(only) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto auto;

      .tile-0 {
        grid-area: 1 / 1 / 2 / 2;
      }

      .tile-1 {
        grid-area: 1 / 2 / 2 / 3;
      }

      .tile-2 {
        grid-area: 1 / 3 / 2 / 4;
      }

      .tile-3 {
        grid-area: 2 / 1 / 3 / 4;
      }
    }
    @include desktop-small(min) {
      grid-template-columns: repeat(4, 1fr);
    }

  }

  &.size-5 {
    @include tablet(min) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, max-content);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 4;
      }

      .tile-1 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .tile-2 {
        grid-area: 2 / 2 / 3 / 3;
      }

      .tile-3 {
        grid-area: 2 / 3 / 3 / 4;
      }

      .tile-4 {
        grid-area: 3 / 1 / 4 / 4;
      }
    }

    @include desktop-small(min) {
      grid-template-columns: repeat(2, 1fr);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-3 {
        grid-area: 3 / 1 / 4 / 2;
      }

      .tile-4 {
        grid-area: 3 / 2 / 4 / 3;
      }
    }
  }

  &.size-6, &.size-7, &.size-8, &.size-9, &.size-10 {
    @include tablet(min) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: minmax(180px, max-content) minmax(364px, max-content) minmax(364px, max-content) minmax(180px, max-content);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-1 {
        grid-area: 3 / 1 / 4 / 2;
      }

      .tile-2 {
        grid-area: 3 / 2 / 4 / 3;
      }

      .tile-3 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .tile-4 {
        grid-area: 2 / 2 / 3 / 3;

      }

      .tile-5 {
        grid-area: 4 / 1 / 5 / 3;
      }
    }

    @include desktop-small(min) {
      //important needs to overwrite size-7, size-8, size-9, size-10 tablet-min rules
      grid-template-columns: repeat(4, 1fr) !important;
      grid-template-rows: minmax(228px, max-content) minmax(104px, max-content) minmax(228px, max-content);

      .tile-1 {
        grid-area: 1 / 3 / 3 / 4 !important;
      }

      .tile-2 {
        grid-area: 1 / 4 / 3 / 5 !important;
      }

      .tile-3 {
        grid-area: 2 / 1 / 4 / 2 !important;
      }

      .tile-4 {
        grid-area: 2 / 2 / 4 / 3 !important;
      }

      .tile-5 {
        grid-area: 3 / 3 / 4 / 5 !important;
      }
    }

    @include desktop-medium(min) {
      grid-template-rows: minmax(228px, max-content) minmax(96px, max-content) minmax(228px, max-content);
    }
  }

  &.size-7 {
    @include tablet(min) {
      grid-template-rows: minmax(180px, max-content) minmax(364px, max-content) minmax(364px, max-content) minmax(180px, max-content) minmax(180px, max-content);

      .tile-6 {
        grid-area: 5 / 1 / 6 / 3;
      }
    }

    @include desktop-small(min) {
      grid-template-rows: minmax(228px, max-content) minmax(104px, max-content) minmax(228px, max-content) minmax(180px, max-content);

      .tile-6 {
        grid-area: 4 / 1 / 5 / 5;
      }
    }

    @include desktop-medium(min) {
      grid-template-rows: minmax(228px, max-content) minmax(96px, max-content) minmax(228px, max-content) minmax(180px, max-content);
    }
  }

  &.size-8 {
    @include tablet(min) {
      grid-template-columns: repeat(3, 1fr);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 4;
      }

      .tile-4 {
        grid-area: 2 / 2 / 3 / 3;
      }

      .tile-5 {
        grid-area: 3 / 3 / 4 / 4;
      }

      .tile-6 {
        grid-area: 2 / 3 / 3 / 4;
      }

      .tile-7 {
        grid-area: 4 / 1 / 5 / 4;
      }
    }

    @include desktop-small(min) {
      grid-template-rows: minmax(228px, max-content) minmax(104px, max-content) minmax(228px, max-content) minmax(228px, max-content);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-6 {
        grid-area: 4 / 1 / 5 / 3;
      }

      .tile-7 {
        grid-area: 4 / 3 / 5 / 5;
      }
    }

    @include desktop-medium(min) {
      grid-template-rows: minmax(228px, max-content) minmax(96px, max-content) minmax(228px, max-content) minmax(228px, max-content);
    }
  }

  &.size-9 {
    @include tablet(min) {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: minmax(180px, max-content) minmax(364px, max-content) minmax(364px, max-content) minmax(364px, max-content);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 7;
      }

      .tile-1 {
        grid-area: 3 / 1 / 4 / 3;
      }

      .tile-2 {
        grid-area: 3 / 3 / 4 / 5;
      }

      .tile-3 {
        grid-area: 2 / 1 / 3 / 3;
      }

      .tile-4 {
        grid-area: 2 / 3 / 3 / 5;
      }

      .tile-5 {
        grid-area: 3 / 5 / 4 / 7;
      }

      .tile-6 {
        grid-area: 2 / 5 / 3 / 7;
      }

      .tile-7 {
        grid-area: 4 / 1 / 5 / 4;
      }

      .tile-8 {
        grid-area: 4 / 4 / 5 / 7;
      }
    }

    @include desktop-small(min) {
      grid-template-rows: minmax(228px, max-content) minmax(104px, max-content) minmax(228px, max-content) minmax(364px, max-content);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-6 {
        grid-area: 4 / 1 / 5 / 3;
      }

      .tile-7 {
        grid-area: 4 / 3 / 5 / 4;
      }

      .tile-8 {
        grid-area: 4 / 4 / 5 / 5;
      }
    }

    @include desktop-medium(min) {
      grid-template-rows: minmax(228px, max-content) minmax(96px, max-content) minmax(228px, max-content) minmax(364px, max-content);
    }
  }

  &.size-10 {
    @include tablet(min) {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: minmax(180px, max-content) minmax(364px, max-content) minmax(364px, max-content) minmax(180px, max-content) minmax(364px, max-content);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 7;
      }

      .tile-1 {
        grid-area: 3 / 3 / 4 / 5;
      }

      .tile-2 {
        grid-area: 3 / 5 / 4 / 7;
      }

      .tile-3 {
        grid-area: 2 / 1 / 3 / 3;
      }

      .tile-4 {
        grid-area: 2 / 3 / 3 / 5;
      }

      .tile-5 {
        grid-area: 4 / 1 / 5 / 7;
      }

      .tile-6 {
        grid-area: 2 / 5 / 3 / 7;
      }

      .tile-7 {
        grid-area: 3 / 1 / 4 / 3;
      }

      .tile-8 {
        grid-area: 5 / 1 / 6 / 4;
      }

      .tile-9 {
        grid-area: 5 / 4 / 6 / 7;
      }
    }

    @include desktop-small(min) {
      grid-template-rows: minmax(228px, max-content) minmax(104px, max-content) minmax(228px, max-content) minmax(364px, max-content);

      .tile-0 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .tile-6 {
        grid-area: 4 / 1 / 5 / 2;
      }

      .tile-7 {
        grid-area: 4 / 2 / 5 / 3;
      }

      .tile-8 {
        grid-area: 4 / 3 / 5 / 4;
      }

      .tile-9 {
        grid-area: 4 / 4 / 5 / 5;
      }
    }

    @include desktop-medium(min) {
      grid-template-rows: minmax(228px, max-content) minmax(96px, max-content) minmax(228px, max-content) minmax(364px, max-content);
    }
  }

  @include mobile(max) {
    max-width: unset !important;
  }
}
</style>
